@import url("@chatscope/chat-ui-kit-styles/dist/default/styles.min.css");

.cs-message__content {
  background: white !important;
  font-family: 'Manrope', serif;
  font-style: normal;
}

.cs-message-input__content-editor-wrapper {
  width: 60% !important;
}

.cs-message-input__content-editor-wrapper, .cs-message-input__content-editor {
  background: #F3F5F6 !important;
}

.cs-button--attachment, .cs-button--send {
  color: #40A9FF !important;
}

.cs-button--attachment:hover, .cs-button--send:hover {
  color: #1890FF !important;
}

.cs-message--incoming .cs-message__content-wrapper {
  width: 100%;
}

.cs-message--incoming .cs-message__content {
  /*background: red!important;*/
  border-radius: 0.7rem;
  /*border-color: #ececec!important;*/
  border: 1px solid #ececec;
  box-shadow: 0 0 30px 0 rgb(239 239 239)!important;
}